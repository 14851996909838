import React from "react";

import overwhelmed from "./assets/images/overwhelmed.png";
const HomeImages4 = (props) => {
  return (
    <div className="col text-center pt-5">
      <img className="w-75 mt-5 mx-auto" src={overwhelmed} alt="EasyCryptoBot Overwhelmed from trading?" />
    </div>
  );
};

export default HomeImages4;
